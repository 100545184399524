import React, {useEffect, useState} from "react";
import {CarImageCarousel} from "../../Components/Login/CarImageCarousel";
import {getFromLocalStorage, isEmail, Logout, saveEmailToLocalStorage, setCookie, valueIfNull} from "../../Hooks/UseGenericFunctions";
import {saveKeyAccountNameToLocaleStorage, savePartnerInfo, saveUserInformationToLocaleStorage} from "../../Hooks/UseBaseData";
import {axiosInstance} from "../../Api/AxiosConfig";
import {useLocation} from "react-router-dom";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {registerUserActivity} from "../../Api/StatService";

export const Login = () => {
    const [errorText, setErrorText] = useState('');
    const location = useLocation();
    const [email, setEmail] = useState(valueIfNull(getFromLocalStorage('CARZELLE_LOGIN_EMAIL'), ''));
    const [password, setPassword] = useState('');
    const {fetchGlobalData, loggedIn} = useGlobalDataContext();

    const LoginWithUserNamePassword = () => {
        axiosInstance.post('auth/login', {
            username: email,
            password: password
        })
            .then(function (response) {
                savePartnerInfo(response.data);
                saveUserInformationToLocaleStorage(response.data.userData);
                saveKeyAccountNameToLocaleStorage(response.data.keyAccountUser);
                setCookie('CARZELLE_API_TOKEN', response.data.token, 2);
                fetchGlobalData();
                registerUserActivity(1, 'Logged in username/password');
            })
            .catch(function (error) {
                console.log(error);
                setErrorText('Wrong email address or password');
            });
    }

    const LoginWithQuickLink = (hash) => {
        Logout();
        axiosInstance.post('auth/ql', {
            hash: hash
        })
            .then(function (response) {
                savePartnerInfo(response.data);
                saveUserInformationToLocaleStorage(response.data.userData);
                saveKeyAccountNameToLocaleStorage(response.data.keyAccountUser);
                setCookie('CARZELLE_API_TOKEN', response.data.token, 2);
                fetchGlobalData();
                registerUserActivity(2, 'Quick login');
            })
            .catch(function (error) {
                alert('The link has expired. Please enter your email and password to login');
            });
    }

    const HandleLoginButtonClick = () => {
        if (isEmail(email)) {
            saveEmailToLocalStorage(email);
            setErrorText('');
            LoginWithUserNamePassword();
        } else {
            setErrorText('Please enter a valid e-mail address');
        }
    }

    useEffect(() => {
        if (loggedIn) {
            const searchParams = new URLSearchParams(location.search);
            let redirectToPage = searchParams.get("page");
            const carLink = searchParams.get("carLink");
            window.location.href = '/' + (redirectToPage === null ? '' : redirectToPage) + (carLink === null ? '' : '?carLink=' + carLink);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const hash = searchParams.get("q");

        if (hash !== null) {
            LoginWithQuickLink(hash);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const readyToLogin = () => {
        return isEmail(email) && password.length > 0;
    }

    return (
        <>
            <div className="overflow-auto h-screen w-screen relative flex items-center justify-center">
                <div className='screen-small:w-[900px] h-[650px] rounded-3xl bg-white mx-2 shadow-lg flex items-center z-10'>
                    <div className='mx-2 h-[650px] screen-small:w-[450px] bg-white rounded-3xl screen-small:rounded-l-3xl screen-small:rounded-r-none'>
                        <div className='w-full mt-[50px]'><img src="/images/logo.png" className="m-auto min-w-[200px] max-w-[200px]" alt='Carzelle logo'/></div>

                        <div className='mx-[70px] mt-[40px] font-open-sans'>
                        <div className='text-gradient text-info login-header mb-1'>Welcome back</div>
                            <div className='text-[16px] font-normal mb-[25px]'>Enter your email and password to login to the system</div>
                            <form id="loginForm">
                                <label className='login-label'>Email
                                    <div className='h-[50px] flex items-center screen-small:mb-[10px]'>
                                        <input id='email' type="text" autoComplete="user-name" className='login-input w-full' placeholder='Email' autoFocus={true} value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    {errorText && <div className='text-red-400 text-sm'>{errorText}</div>}
                                </label>
                                <label className='login-label'>Password
                                    <div className='h-[50px] flex items-center screen-small:mb-[5px]'>
                                        <input id='password' type="password" autoComplete="current-password" className='login-input w-full' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                HandleLoginButtonClick();
                                            }
                                        }}/>
                                    </div>
                                    {errorText && <div className='text-red-400 text-sm'>{errorText}</div>}
                                </label>
                                <div className='mb-8'><label className="underline text-xs"><a href="/forgottenpassword">Forgot password</a></label></div>
                                <button type="button" className={`w-full mb-2 mt-4 ${readyToLogin() ? 'blue-gradient-button' : 'gray-gradient-button'}`} disabled={!readyToLogin()} onClick={() => HandleLoginButtonClick()}>Sign in</button>
                            </form>
                            <div className='mt-4 mb-4 text-sm w-full text-center'>Don't have a user? <a className="underline" href="https://signup.carzelle.com">Sign-up here</a></div>
                            <div className='text-sm w-full text-center tracking-tighter font-medium text-gradient text-info'>+45 7241 0000</div>
                            <div className='text-sm w-full text-center tracking-tighter font-medium text-gradient text-info'><a href='mailto:info@carzelle.com'>info@carzelle.com</a></div>
                        </div>
                    </div>

                    <div className='w-[450px] h-[650px] rounded-r-3xl login-header-background flex justify-center relative hidden screen-small:flex'>
                        <div>
                            <div className='w-[300px] mt-[200px]'><CarImageCarousel delay={5000}/></div>
                            <div className='absolute bottom-[50px] left-0 w-full'>
                                <div className='text-3xl font-bold text-center'>The <span className='font-extrabold text-white text-[38px]'>right</span> cars</div>
                                <div className='text-3xl font-bold text-center'>for <span className='font-extrabold text-white text-[38px]'>your</span> market</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}