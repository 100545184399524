import {Loader, LoadingOverlay} from "@mantine/core";
import React from "react";

export const PendingActionWithText = ({title = 'Loading car list ...', visible}) => {
    const waiting = () => {
        return (
            <>
                <Loader color="#CFB77B" type="dots" size={100} className="mx-auto"/>
                <div className='text-2xl font-bold text-center text-purchase-color leading-tight tracking-tight'>{title}</div>
            </>
        )
    }
    return (
        <LoadingOverlay visible={visible} loaderProps={{children: waiting()}} zIndex={20} overlayProps={{radius: "sm", blur: 2}}/>
    )

}