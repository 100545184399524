import {XCircleIcon} from "@heroicons/react/24/solid";
import React from "react";
import {axiosInstance} from "../../Api/AxiosConfig";

export const RemoveOfferFromBasketButton = ({ basketHash, offer_amount, allowNonOfferRemoval = false, fnCallAfterRemoved }) => {
    const removeFromBasket = (hash) => {
        console.log(hash);
        axiosInstance
            .delete("shop/remove-from-basket/" + hash)

            .then(() => {
                fnCallAfterRemoved();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }
    return (
        <div className={`absolute top-[-14px] right-[-14px] cursor-pointer ${offer_amount === null && !allowNonOfferRemoval ? 'hidden' : ''}`} onClick={() => removeFromBasket(basketHash)}>
            <XCircleIcon className="w-4 h-4 text-red-400"/>
        </div>
    )
}