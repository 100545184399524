import {CarAttribute} from "./CarAttribute";
import {CarImageModal} from "./CarImageModal";
import {CarNavigationThumbs} from "./CarNavigationThumbs";
import React from "react";
import {SuggestedPriceCalculation} from "./SuggestedPriceCalculation";

export const CarDetails = ({car, data, showPrice, showImageModal, setShowImageModal, showImageIndex, handleThumbnailClick}) => {
    return (
        <div className={`border-t border-t-gray-200`}>
            <div className="ml-6 mt-6 font-bold">{car.designation}</div>
            <div className='flex mt-4 ml-6'>
                <div className=''>
                    <div className='flex'>
                        <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                            <CarAttribute label='Car #' value={car.car_id}/>
                            <CarAttribute label='VIN' value={data.cars.vin_no.value}/>
                            <CarAttribute label='Make' value={data.cars.make_name.value}/>
                            <CarAttribute label='Model' value={data.cars.model.value}/>
                            <CarAttribute label='Model year' value={data.cars.model_year.value}/>
                            <CarAttribute label='First reg.' value={data.cars.reg_date.value}/>
                            <CarAttribute label='Last serv.' value={data.cars.last_service_date.value} hideWhenEmpty={true}/>
                            <CarAttribute label='Service km' value={data.cars.last_service_km.value} hideWhenEmpty={true}/>
                        </div>
                        <div className='border-r border-l-solid w-[1px]'></div>
                        <div className='flex-1 text-sm ml-4 max-w-[250px] min-w-[250px]'>
                            <CarAttribute label='KM' value={data.cars.km.value}/>
                            <CarAttribute label='Color' value={data.cars.color.value === null ? data.cars.factory_color.value : data.cars.color.value}/>
                            <CarAttribute label='Car type' value={data.cars.car_type_name.value}/>
                            <CarAttribute label='Body type' value={data.cars.body_type_name.value}/>
                            <CarAttribute label='Seats' value={data.cars.seats.value}/>
                        </div>
                        <div className='border-r border-l-solid w-[1px]'></div>
                        <div className='flex-1 text-sm ml-4 max-w-[250px] min-w-[250px]'>
                            <CarAttribute label='Fuel' value={data.cars.fuel_name.value}/>
                            <CarAttribute label='CO2 rating' value={data.cars.co2_rating_wltp.value}/>
                            <CarAttribute label='Gear' value={data.cars.gear_name.value}/>
                            {data.cars.battery_size.value !== null && <CarAttribute label='Battery size' value={data.cars.battery_size.value}/>}
                            {data.cars.fuel_name.value !== 'Electric' && <CarAttribute label='Engine size' value={data.cars.engine_size.value}/>}
                            <CarAttribute label='HP' value={data.cars.horsepower.value}/>
                        </div>
                    </div>

                    <div className="mt-4">
                        {data.documents.length > 0 &&
                            <div className="mb-4">
                                <div className="font-bold text-sm mb-1">Documents</div>
                                <div className='flex-1 text-sm max-w-[250px] min-w-[250px]'>
                                    {data.documents.map((item) => (
                                        <div key={item.file_url} className="flex mb-1">
                                            <div><img src="/images/pdf_new.png" alt="pdf document" className="w-6 mr-3"/></div>
                                            <div><a href={item.file_url} target="_blank" className="underline" rel="noopener noreferrer">{item.cars_documents_types_name}</a></div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        }
                    </div>

                    {showPrice && data.dkPrice.status &&
                        <SuggestedPriceCalculation car={car} data={data}/>
                    }

                    {data.cars.condition.value !== null &&
                        <div className='mt-5'>
                            <div className='text-base font-semibold mb-3'>Condition</div>
                            <div className='max-w-[750px] whitespace-normal'>
                                {data.cars.condition.value}
                            </div>
                        </div>
                    }

                    {data.equipment.length > 0 &&
                        <div className='mt-5'>
                            <div className='text-base font-semibold mb-3'>Equipment</div>
                            <div className='max-w-[750px] whitespace-normal'>
                                {data.equipment.map((item) => (
                                    <span key={item.name} className='mb-[10px] whitespace-nowrap mr-2 px-2 py-1 rounded bg-gray-200 inline-block text-sm' style={{lineHeight: '15px'}}>{item.name}</span>
                                ))}
                            </div>
                        </div>
                    }

                    <div className='flex flex-wrap items-center my-4'>
                        <CarImageModal images={data.images.all} visible={showImageModal} setVisible={setShowImageModal} imageIndex={showImageIndex}/>
                        <CarNavigationThumbs images={data.images.all} maxImagesNumber={20} onClick={handleThumbnailClick}/>
                    </div>

                </div>
            </div>

        </div>
    );
}