import {CarzelleLogo} from "../../Brand/CarzelleLogo";

export const UnderMaintenance = () => {
    return (
        <div className="relative w-screen h-screen">
            <img src={'/images/UnderMaintenance.jpg'} alt="Under Maintenance" className="w-full h-full object-cover"/>
            <div className="absolute top-0 right-0 w-full screen-medium:w-[70%] h-full bg-gradient-to-l from-white to-transparent via-white/90 flex justify-center items-start">
                <div className="mt-[200px] screen-medium:ml-[300px] text-center screen-medium:text-left">
                    <div className="text-3xl mt-[200px] text-gray-700">Carzelle Partner Portal</div>
                    <div className="text-5xl font-bold">We're launching a new version</div>
                    <div className="text-2xl text-gray-700">We will be back very soon</div>
                    <div className="absolute bottom-6 right-6"><CarzelleLogo width={350} withText={true}/></div>
                </div>
            </div>
        </div>
    )
}