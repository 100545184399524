import React from "react";
import {Tooltip} from "@mantine/core";

export const CarStatusPill = ({title, explanation, bgColor, foreColor='text-white', className}) => {
    return (
        <Tooltip label={explanation} position='bottom' withArrow multiline w={150} disabled={explanation===''} openDelay={1500}>
            <div className={'py-1 px-2 text-center rounded whitespace-nowrap inline-block ' + bgColor + ' ' + className + ' ' + foreColor} style={{minWidth: '100px'}}>
                {title}
            </div>
        </Tooltip>
    )
}