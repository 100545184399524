import React, {useEffect, useState} from "react";
import {DeleteUrlParameter, getCookie} from "../Hooks/UseGenericFunctions";
import {CarListRow} from "../Components/Cars/CarListRow";
import {ShoppingBasket} from "../Components/ShoppingBasket/ShoppingBasket";
import {CustomerManagerInformationBox} from "../Components/Information/CustomerManagerInformationBox";
import {CarFilterBar, InitControlValueData} from "../Components/Navigation/CarFilterBar";
import {CarSortBar} from "../Components/Navigation/CarSortBar";
import {axiosInstance} from "../Api/AxiosConfig";
import {VATIcon} from "../Components/Cars/VATIcon";
import {Waiting} from "../Components/System/Waiting";
import {CarSearchBox} from "../Components/Navigation/CarSearchBox";
import {BrandPillSelection} from "../Components/Brand/BrandPillSelection";
import {Tooltip} from "@mantine/core";
import {CarFilterMobile} from "../Components/Navigation/CarFilterMobile";
import {CarSortMobile} from "../Components/Navigation/CarSortMobile";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/24/solid";
import {CarMobileCard} from "../Components/Cars/CarMobileCard";
import {ShoppingBasketMobile} from "../Components/ShoppingBasket/ShoppingBasketMobile";
import {ShowroomMode} from "../Components/Navigation/ShowroomMode";
import {useLocation} from "react-router-dom";
import {GotoCheckButton} from "./Checkout/GotoCheckButton";

export const AvailableCars = ({fnUpdate, basketData, showSearch, selected}) => {
    const [data, setData] = useState();
    const [dataSold, setDataSold] = useState();
    const [showroomMode, setShowroomMode] = useState(false);
    const [makes, setMakes] = useState();
    const [updateData, setUpdateData] = useState(false);
    const [sortByField, setSortByField] = useState('designation');
    const [filterData, setFilterData] = useState(InitControlValueData);
    const [selectedCarId, setSelectedCarId] = useState(null);
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [basketVisible, setBasketVisible] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const carLink = searchParams.get("carLink");

    axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + getCookie('CARZELLE_API_TOKEN');

    const filterList = (data) => {
        let dataToFilter = data.filter((car) => car.buy_status !== 'listing');

        if (filterData.make_id !== null) {
            dataToFilter = dataToFilter.filter((car) => car.make_id.toString() === filterData.make_id);
        }

        if (filterData.in_basket) {
            dataToFilter = dataToFilter.filter((car) => car.in_basket !== null);
        }

        if (filterData.search_text !== '') {
            dataToFilter = dataToFilter.filter((car) => (car.designation + car.vin_no + car.car_id + car.info_line_1 + car.info_line_2).toLowerCase().includes(filterData.search_text.toLowerCase()));
        }

        if (filterData.hide_sold) {
            dataToFilter = dataToFilter.filter((car) => car.is_sold === null);
        }

        if (filterData.buy_status) {
            dataToFilter = dataToFilter.filter((car) => car.buy_status === 'fast_delivery');
        }

        if (sortByField !== 'price') {
            dataToFilter = dataToFilter.sort((a, b) => {
                if (a[sortByField] < b[sortByField]) {
                    return -1;
                } else if (a[sortByField] > b[sortByField]) {
                    return 1;
                } else {
                    // If the primary sortByField values are equal, fall back to sorting by car_id
                    return a.hash - b.hash;
                }
            });
        } else {
            dataToFilter = dataToFilter.sort((a, b) => {
                if (parseInt(a.price) < parseInt(b.price)) {
                    return -1;
                } else if (parseInt(a.price) > parseInt(b.price)) {
                    return 1;
                } else {
                    // If the prices are equal, fall back to sorting by hash
                    return a.hash - b.hash;
                }
            });
        }

        return dataToFilter;
    }

    useEffect(() => {
        if (selected) {
            axiosInstance
                .get('/shop/buy-list')

                .then((response) => {
                    setMakes(response.data.makes);
                    setData(response.data.list);
                    setDataSold(response.data.sold);

                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        fnUpdate();
    }, [updateData]); // eslint-disable-line react-hooks/exhaustive-deps

    const scrollToCard = (id) => {
        // const parentElement = document.getElementById('container_22');
        // const elementToScrollTo = document.getElementById(id);
        // if (elementToScrollTo && parentElement) {
        //     scrollIntoViewUniversal(parentElement, elementToScrollTo);
        // }
    }

    // useEffect(() => {
    //     if (data) {
    //         let a = document.getElementById('container_22');
    //         document.getElementById('container_22').scrollTo(0, 3000);
    //     }
    // }, [data]);

    useEffect(() => {
        if (selectedCarId) {
            scrollToCard('car_mobile_card_' + selectedCarId);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // We scroll to both the mobile view and desktop view - one will fail, but that error is ignored
    useEffect(() => {
        if (carLink && data) {
            scrollToCard('car_mobile_card_' + carLink);
            scrollToCard('car_' + carLink);
            DeleteUrlParameter('carLink');
            setSelectedCarId(carLink);
        }
    }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

    if (data) {
        return (
            <div id='container_22' className="max-h-screen overflow-y-scroll">
                <div className={`small:hidden sticky top-0 z-10 pb-4 bg-background-color screen-small:block ${showFilterOptions ? 'shadow-lg' : ''} `} style={{display: showFilterOptions ? "shadow-xl" : ""}}>
                    <div className='hidden mr-4 ml-10 mt-4 flex screen-medium:flex items-center'>
                        <div className="max-w-[1210px] mr-4">
                            <CarFilterBar makes={makes} showroomMode={showroomMode} setShowroomMode={setShowroomMode} fnFilterChange={(filterValues) => setFilterData(filterValues)}/>
                        </div>
                        <div className="screen-large:block hidden mr-4">
                            <CarSortBar fnSortChange={(value) => setSortByField(value)}/>
                        </div>
                        <div className="screen-large:hidden">
                            <div className="hidden screen-small:block"><GotoCheckButton numberOfItemsInBasket={basketData.basket_counts}/></div>
                        </div>
                    </div>

                    <div className={`flex screen-medium:hidden ml-3 mr-3 screen-small:ml-6 screen-small:mr-4 items-center mt-4 max-w-[900px] ${showSearch ? '' : 'hidden'}`}>
                        <CarSearchBox fnSearchValueChange={(filterValues) => setFilterData(filterValues)}/>
                    </div>

                    <div className="screen-medium:hidden ml-6 flex items-center mt-4 text-xs text-gray-500 cursor-pointer hover:underline">
                        <div style={{display: showFilterOptions ? 'none' : 'flex'}} onClick={() => setShowFilterOptions(true)}>
                            <div className="flex items-center">Filter and sort options <ChevronDownIcon className="w-3 ml-3"/></div>
                        </div>
                        <div style={{display: showFilterOptions ? 'flex' : 'none'}} onClick={() => setShowFilterOptions(false)}>
                            <div className="flex items-center">Hide filter and sort options <ChevronUpIcon className="w-3 ml-3"/></div>
                        </div>
                    </div>
                    <div className={`screen-medium:hidden z-10 ml-6 ` + (showFilterOptions ? '' : ' hidden ')}>
                        <div className="flex items-center mt-4">
                            <Tooltip label="Show/hide prices" position="bottom" transition="pop" openDelay={1500}>
                                <ShowroomMode value={showroomMode} changeValue={(value) => setShowroomMode(value)}/>
                            </Tooltip>
                        </div>
                        <div className='flex items-center mt-4'>
                            <BrandPillSelection makes={makes} fnFilterChange={(filterValues) => setFilterData(filterValues)}/>
                        </div>

                        <div className='flex items-center mt-2'>
                            <CarFilterMobile fnFilterChange={(filterValues) => setFilterData(filterValues)}/>
                        </div>

                        <div className='flex items-center mt-4'>
                            <CarSortMobile fnSortChange={(value) => setSortByField(value)} activeSortIndex={1}/>
                        </div>
                    </div>
                </div>

                <div className="screen-small:mb-8 mt-4 flex">
                    <div className='hidden screen-small:block flex-grow mr-4 ml-6 screen-medium:ml-10 max-w-[1330px] min-w-[900px] screen-medium:min-w-[1280px] '>
                        {filterList(data).map((car) =>
                            (
                                <div key={'car_' + car.hash} id={'car_' + car.hash}>
                                    <div key={'car_' + car.hash} className="screen-mobile:hidden">
                                        <CarListRow car={car} fnUpdate={() => setUpdateData(!updateData)} showPrice={!showroomMode} selectedCarId={selectedCarId} setSelectedCarId={setSelectedCarId}/>
                                    </div>
                                </div>
                            ))
                        }

                        <div className="font-bold text-xl mb-4 mt-8 capitalize" style={{display: filterList(dataSold).length > 0 ? "block" : "none"}}>Cars Sold Last 24 Hours</div>
                        {filterList(dataSold).map((car) =>
                            (
                                <div key={'car_' + car.hash} id={'car_' + car.hash}>
                                    <div key={'car_' + car.hash} className="screen-mobile:hidden">
                                        <CarListRow car={car} fnUpdate={() => setUpdateData(!updateData)} showPrice={!showroomMode} selectedCarId={selectedCarId} setSelectedCarId={setSelectedCarId}/>
                                    </div>
                                </div>
                            ))
                        }

                        <div className='w-[1000px] flex items-center justify-center font-medium text-3xl'>{filterList(data).length === 0 ? 'No cars available with the selected filters' : ''}</div>
                    </div>

                    <div className='w-[450px] relative sticky top-0'>
                        <div className="screen-large:block hidden">
                            <ShoppingBasket basketData={basketData} showPrice={!showroomMode} updateBasket={updateData} fnUpdate={() => setUpdateData(!updateData)} showShadow={false}/>
                        </div>
                        <div className='mt-[30px] hidden screen-large:block'><CustomerManagerInformationBox title='Your personal contact'/></div>
                    </div>
                </div>

                <div className="screen-small:mb-[30px]"></div>

                <div className="fixed right-6 top-6 text-white cursor-pointer screen-small:hidden" onClick={() => setBasketVisible(!basketVisible)}>
                    <div className="relative">
                        <div className={"flex material-symbols-outlined text-[30px] font-light "}>
                            shopping_cart
                        </div>
                        <div className={'absolute top-[-5px] right-[-5px] rounded-full flex items-center justify-center text-sm w-5 h-5 bg-white text-gray-500 ' + (basketData.length > 0 ? '' : 'hidden')}>
                            {basketData.length}
                        </div>
                    </div>
                </div>

                <div className="fixed left-0 top-0 z-10 min-h-[200px] screen-small:hidden" style={{display: basketVisible ? 'block' : 'none'}}>
                    <div className="flex justify-center">
                        <ShoppingBasketMobile basketData={basketData} showPrice={true} fnUpdate={fnUpdate} showShadow={true} close={() => setBasketVisible(false)}/>
                    </div>
                </div>

                <div id="mobile_card_container" className="mb-8 mx-2 screen-small:hidden">
                    {filterList(data).map((car) =>
                        (
                            <div id={'car_mobile_card_' + car.hash} key={'car_' + car.hash}>
                                <div key={'car_mobile_card_' + car.hash} className=''>
                                    <CarMobileCard car={car} scrollIntoView={scrollToCard} fnUpdate={() => setUpdateData(!updateData)} showPrice={!showroomMode} selectedCarId={selectedCarId} setSelectedCarId={setSelectedCarId}/>
                                </div>
                            </div>
                        ))
                    }

                    <div className="font-bold text-xl mb-4 mt-8" style={{display: filterList(dataSold).length > 0 ? "block" : "none"}}>Cars sold today</div>
                    {filterList(dataSold).map((car) =>
                        (
                            <div id={'car_mobile_card_' + car.hash} key={'car_' + car.hash}>
                                <div key={'car_mobile_card_' + car.hash} className=''>
                                    <CarMobileCard car={car} scrollIntoView={scrollToCard} fnUpdate={() => setUpdateData(!updateData)} showPrice={!showroomMode} selectedCarId={selectedCarId} setSelectedCarId={setSelectedCarId}/>
                                </div>
                            </div>
                        ))
                    }
                    <div className='w-full font-medium text-2xl text-center px-4'>
                        <div>{filterList(data).length === 0 ? 'No cars available with the selected filters' : ''}</div>
                    </div>
                </div>

                <div className="screen-small:hidden mb-8 mx-2">
                    <CustomerManagerInformationBox title='Need help?' className="shadow"/>
                </div>

                <div className="hidden mb-[80px] screen-mobile:block">
                    <div className='flex items-center justify-center text-xs'>
                        <div className='mr-2 flex items-center'><VATIcon vatStatusId={4}/>&nbsp;&nbsp;VAT&nbsp;Included/Margin</div>
                        <div className='mr-2 flex items-center'><VATIcon vatStatusId={0}/>&nbsp;&nbsp;VAT&nbsp;Excluded/Net</div>
                    </div>
                </div>

                <div className='h-[100px]'></div>
            </div>
        )
    } else {
        return (
            <Waiting/>
        )
    }
}