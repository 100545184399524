import Countdown from "react-countdown";
import { ClockIcon } from "@heroicons/react/24/solid";
import { CarzelleTooltip } from "../../Components/System/Messages/CarzelleTooltip";

export const PartnerListingCountDown = ({ seconds, className }) => {
    const targetDate = Date.now() + seconds * 1000;

    const renderer = ({ total, days, hours, minutes, completed }) => {
        if (completed) {
            return <span className="rounded-md text-xs bg-red-500 text-white pl-1 pr-2 py-1">Pending</span>;
        } else {
            // Calculate total days, hours, and minutes from the remaining time
            const totalDays = Math.floor(total / (1000 * 60 * 60 * 24)); // Total days
            const remainingHours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)); // Remaining hours after calculating days

            return (
                <CarzelleTooltip message="Time left before the listing expires">
                    <span className="rounded-md text-xs bg-slate-400 text-white pl-1 pr-2 py-1">
                        <ClockIcon className="w-3 h-3 inline mr-1" />
                        {totalDays > 0 ? `${totalDays}d ` : ''}
                        {remainingHours}h {minutes}m
                    </span>
                </CarzelleTooltip>
            );
        }
    };

    return (
        <span className={className}>
            <Countdown date={targetDate} renderer={renderer} />
        </span>
    );
};
