import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import * as PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {ShoppingBagIcon} from "@heroicons/react/24/outline";
import {axiosInstance} from "../../Api/AxiosConfig";
import {ExplanationModal} from "../../Components/System/Messages/ExplanationModal";
import {useDisclosure} from "@mantine/hooks";
import {Checkbox} from "@mantine/core";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {PurchaseTypePill} from "../../Components/Information/PurchaseTypePill";
import {RemoveOfferFromBasketButton} from "../../Components/ShoppingBasket/RemoveOfferFromBasketButton";
import {PendingActionWithText} from "../../Components/System/PendingActionWithText";
import {SoldPill} from "../../Components/Information/SoldPill";
import {Terms} from "../Terms/Terms";
import {ShortMessageModal} from "../../Components/System/Messages/ShortMessageModal";

export const StatusAndCheckoutButton = ({cars, sum, showBasket = false, currency, fnUpdate}) => {
    const [showConfirmSection, setShowConfirmSection] = useState(showBasket);
    const [showPulse,] = useState(false);
    const [opened, {open, close}] = useDisclosure(false);
    const [opened2, { open: openAcceptanceMessage, close: closeAcceptanceMessage }] = useDisclosure(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [offerCars, setOfferCars] = useState([]);
    const [purchaseNowCars, setPurchaseNowCars] = useState([]);
    const [orderCheckoutPending, setOrderCheckoutPending] = useState(false);
    const [currentError, setCurrentError] = useState(null);
    const [showErrorOverlay, setShowErrorOverlay] = useState(false);

    useEffect(() => {
        setOfferCars(cars.filter(car => car.offer_amount !== null));
        setPurchaseNowCars(cars.filter(car => car.offer_amount === null));
        setCurrentError(null);
    }, [cars]);

    useEffect(() => {
        setShowErrorOverlay(currentError !== null);
    }, [currentError]);

    let carNumberTextOffers = offerCars.length > 1 ? 'cars' : 'car';
    let carNumberTextPurchaseNow = purchaseNowCars.length > 1 ? 'cars' : 'car';

    let confirmButtonLabel = '';
    if (offerCars.length > 0 && purchaseNowCars.length > 0) {
        confirmButtonLabel = 'Purchase ' + purchaseNowCars.length + ' ' + carNumberTextPurchaseNow + ' and make offer on ' + offerCars.length + ' ' + carNumberTextOffers;
    }

    if (offerCars.length > 0 && purchaseNowCars.length === 0) {
        confirmButtonLabel = 'Make offer on ' + offerCars.length + ' ' + carNumberTextOffers;
    }

    if (offerCars.length === 0 && purchaseNowCars.length > 0) {
        confirmButtonLabel = 'Purchase ' + purchaseNowCars.length + ' ' + carNumberTextPurchaseNow;
    }

    const PurchaseTypeHeader = ({title}) => {
        return (<div className="text-lg font-bold mb-2 text-gray-600">{title}</div>);
    }

    const placeOrder = () => {
        if (!termsAccepted) {
            openAcceptanceMessage();
            return;
        }
        if (termsAccepted && !orderCheckoutPending) {
            axiosInstance
                .post("shop/confirm-basket")

                .then((response) => {
                    setOrderCheckoutPending(false);
                    console.log(response);
                    if (response.status === 200 && response.data.error === false) {
                        setTermsAccepted(false);
                        console.log('terms accepted set to false');
                        setCurrentError(null);
                        setShowConfirmSection(false);
                        fnUpdate();
                    }
                    if (response.status === 200 && response.data.error === true) {
                        setCurrentError(response.data.messages);
                    }
                })

                .catch((error) => {
                    setOrderCheckoutPending(false);
                    console.error("Error fetching data:", error);
                });
        }
        setOrderCheckoutPending(true);
    }

    const BasketLine = ({car, buyNow}) => {
        return (
            <div key={car.cars_hash} className="relative flex items-start justify-between mb-1">
                <div className="flex items-center justify-between">
                    <div className={`${car.is_sold === 1 ? 'line-through' : ''} mr-4 `}>
                        #{car.cars_id}
                    </div>
                    <div className={`${car.is_sold === 1 ? 'line-through' : ''} mr-4 `}>
                        {car.cars_designation}
                    </div>
                    <div>
                        {car.is_sold === 1 &&
                            <SoldPill/>
                        }
                        {car.is_sold === null &&
                            <PurchaseTypePill car={car}/>
                        }
                    </div>
                </div>
                <div className={`${car.is_sold === 1 ? 'line-through' : ''}`}>
                    {formatNumberWithSeparator(Number(buyNow ? car.price : car.offer_amount), ".")}&nbsp;{car.currency}
                </div>
                <RemoveOfferFromBasketButton offer_amount={car.offer_amount} basketHash={car.cars_hash} allowNonOfferRemoval={true} fnCallAfterRemoved={fnUpdate}/>
            </div>
        )
    }

    return (
        <>
            <div className={`flex items-center relative ${showPulse ? 'animate-pulsate' : ''}`}>
                <div id="shopping_bag" className={`flex ${showConfirmSection ? 'shadow-xl' : 'hover:scale-105'} transition-transform duration-150 ease-in-out items-center justify-center bg-purchase-color text-xl text-gray-800 rounded-full p-2 z-20 shadow`}>
                    <div className={`relative pr-4 flex items-center p-2 cursor-pointer max-w-max max-h-[40px] hover:animate-pulse `} onClick={() => {
                        setShowConfirmSection(!showConfirmSection)
                    }}>
                        <div className="mr-4">
                            <ShoppingBagIcon className="w-8 h-8"/>
                        </div>
                        <div className="whitespace-nowrap"><span className="font-bold text-xl">{cars.length}</span> items</div>
                    </div>
                    <div className={`${showConfirmSection && cars.length > 0 ? '' : 'hidden'} top-[110%] w-[820px] shadow-xl right-0 rounded-b-xl p-4 rounded-l-xl rounded-r-xl absolute z-20 bg-purchase-color`}>
                        <PendingActionWithText title="Placing your order" visible={orderCheckoutPending}/>
                        <div className="p-4 relative">
                            {cars.length > 0 &&
                                <div>
                                    <div className="absolute top-2 right-2 rotate-on-hover z-10">
                                        <XMarkIcon className="w-8 h-8 cursor-pointer" onClick={() => setShowConfirmSection(false)}/>
                                    </div>
                                    <div className="font-bold text-2xl mb-4 whitespace-nowrap">Basket content</div>
                                    <div className="text-xs">
                                        <div className={`${purchaseNowCars.length > 0 ? '' : 'hidden'} mt-4`}>
                                            <PurchaseTypeHeader title="Purchases"/>
                                            {purchaseNowCars.map((car) => (
                                                <BasketLine key={car.basketHash} car={car} buyNow={true}/>
                                            ))}
                                        </div>

                                        <div className={`mt-4 ${offerCars.length > 0 ? '' : 'hidden'}`}>
                                            <PurchaseTypeHeader title="Offers"/>
                                            {offerCars.map((car) => (
                                                <BasketLine key={car.basketHash} car={car} buyNow={false}/>
                                            ))}
                                        </div>

                                        <div className="flex items-center justify-between font-bold mt-4">
                                            <div>Total</div>
                                            <div>{formatNumberWithSeparator(sum, ".")}&nbsp;{currency}</div>
                                        </div>
                                        <div className={`${currentError ? 'visible' : 'hidden'} mt-4 text-red-500 text-sm`}>
                                            <div className="flex items-start justify-between">
                                                <div className="bg-red-400 px-2 py-1 rounded mr-4 text-white whitespace-nowrap">
                                                    Your basket wasn't submitted!
                                                </div>
                                                <div>{currentError}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex items-center justify-between mt-6" onClick={(e) => e.stopPropagation()}>
                                        <div className="mr-4 flex items-center">
                                            <div className="mr-2">
                                                <Checkbox label="" styles={{label: {fontSize: '12px'}}} value={termsAccepted} onChange={(e) => setTermsAccepted(e.currentTarget.checked)}/>
                                            </div>
                                            <div className="text-xs">
                                                I understand that my offers are binding and I agree to <span className="underline cursor-pointer" onClick={() => open()}>Carzelles terms</span>
                                            </div>
                                        </div>
                                        <div>
                                            <button className={`${termsAccepted ? 'bg-blue-400' : 'bg-gray-400'} px-4 py-1 text-white text-sm rounded grow-on-hover-5`} onClick={placeOrder}>{confirmButtonLabel}</button>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <ExplanationModal HelpComponent={<Terms showBorders={false}/>} opened={opened} close={close} title="Terms"/>
            <ShortMessageModal message={<div>Please accept terms first</div>} close={closeAcceptanceMessage} opened={opened2} />
        </>
    )
}

StatusAndCheckoutButton.propTypes = {
    sum: PropTypes.any,
    numberOfCars: PropTypes.any,
    currency: PropTypes.any,
    fnUpdate: PropTypes.func,
};