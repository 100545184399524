import {Modal} from "@mantine/core";
import React from "react";
import {ExclamationTriangleIcon} from "@heroicons/react/24/outline";

export const ShortMessageModal = ({message, opened, close, onClose, width = "800px"}) => {
    return (
        <div>
            <Modal
                opened={opened}
                onClose={() => {
                    close();
                    onClose();
                }}
                centered
                withCloseButton={false}
                overlayProps={{
                    backgroundOpacity: 0.15,
                }}
                styles={{
                    content: {overflowY: 'visible', border: '4px solid #EECDC4', backgroundColor: '#FAEFEB', borderRadius: '10px'},
                    body: {width: width}
                }}>
                <div className="flex items-center justify-between">
                    <div className="text-gray-700 overflow-auto block">
                        <div className="flex items-center">
                            <div className="mr-6"><ExclamationTriangleIcon className="w-12 h-12 bg-red-600 text-white rounded-full p-2"/></div>
                            <div>{message}</div>
                        </div>
                    </div>
                    <div>
                        <button className="bg-[#EECDC4] text-white rounded-xl py-2 px-10 text-sm grow-on-hover-15" onClick={close}>OK</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}