import {LeftNavigation} from "../../Components/Navigation/LeftNavigation";
import React, {useEffect, useState} from "react";
import {Presales} from "../Presales/Presales";
import {Checkout} from "../Checkout/Checkout";
import {Terms} from "../Terms/Terms";
import {useParams} from "react-router-dom";
import {AvailableCars} from "../AvailableCars";
import {Orders} from "../Orders/Orders";
import Pusher from "pusher-js";
import {getPusherCluster, getPusherKey} from "../../Hooks/UseBaseData";
import {useGlobalDataContext} from "../../GlobalState/GlobalDataProvider";
import {ExplanationModal} from "../../Components/System/Messages/ExplanationModal";
import {NewVersionAvailable} from "../../Components/System/Messages/NewVersionAvailable";
import {useDisclosure} from "@mantine/hooks";

export const Dashboard = () => {
    const [updateData, setUpdateData] = useState(false);
    const { basketData, fetchGlobalData } = useGlobalDataContext();
    const { menuItem } = useParams();
    const [opened, {open, close}] = useDisclosure(false);

    const pusher = new Pusher(getPusherKey(), {
        cluster: getPusherCluster(),
    });

    let channel = pusher.subscribe('trade');
    channel.bind('carUpdate', function (data) {
        setUpdateData(!updateData);
    });
    channel.bind('newVersion', function (data) {
        open();
    });

    return (
        <div className="min-h-screen">
            {/*<ScreenSizeIndicator/>*/}
            <div className="hidden screen-small:block">
                <LeftNavigation chosenMenuItemId={menuItem}/>
            </div>

            <div className="screen-small:pb-4 pl-4 screen-small:ml-14">
                {menuItem === '10' && <div className="ml-10 mt-10 text-4xl font-bold">My Carzelle</div>}
                {menuItem === '20' && <div><AvailableCars fnUpdate={fetchGlobalData} basketData={basketData} showSearch={true} showroomMode={false} selected={Number(menuItem)-10} /></div>}
                {menuItem === '30' && <div>
                    <div className="screen-small:block"><Presales fnUpdateGlobalData={fetchGlobalData} pushUpdate={updateData} /></div>
                </div>}
                {menuItem === '40' && <div className="h-full max-h-screen overflow-auto"><Checkout selected={2} /></div>}
                {menuItem === '50' && <div className="h-full max-h-screen overflow-auto"><Orders selected={4}/></div>}
                {menuItem === '70' && <div className="h-full max-h-screen overflow-auto"><Terms selected={5} /></div>}
            </div>

            <ExplanationModal HelpComponent={<NewVersionAvailable />} opened={opened} close={close} title="New version"/>
        </div>
    )
}