import {Modal} from "@mantine/core";
import {XMarkIcon} from "@heroicons/react/20/solid";
import {InformationCircleIcon} from "@heroicons/react/24/solid";
import {CarzelleLogo} from "../../Brand/CarzelleLogo";
import React from "react";

export const ExplanationModal = ({HelpComponent, title, opened, close, width = "800px"}) => {
    return (
        <div>
            <Modal
                opened={opened}
                onClose={close}
                centered
                withCloseButton={false}
                overlayProps={{
                    backgroundOpacity: 0.15,
                }}
                classNames={{}}
                styles={{
                    content: {overflowY: 'visible', border: '3px solid #E5E7EB', borderRadius: '30px'},
                    body: {width: width}
                }}>
                <div className="absolute top-[-35px] flex justify-start w-full">
                    <div className="rounded-full w-[65px] bg-gray-200 flex justify-center items-center">
                        <InformationCircleIcon className="w-[65px] h-[65px] text-primary-color"/>
                    </div>
                </div>
                <div className="absolute top-4 right-5 rotate-on-hover z-10">
                    <XMarkIcon className="w-8 h-8 cursor-pointer" onClick={close}/>
                </div>
                <div className="mt-12">
                    <div className="max-h-[50vh] overflow-y-auto">
                        <div className="text-gray-700 overflow-auto block">
                            {HelpComponent}
                        </div>
                    </div>
                </div>
                <div className="flex justify-end w-full mb-2 mt-4">
                    <div>
                        <CarzelleLogo width={150}/>
                    </div>
                </div>
            </Modal>
        </div>
    )
}