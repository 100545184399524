import {CheckIcon} from "@heroicons/react/24/solid";

export const OkButton = ({onClick, title, className}) => {
    return (
        <button onClick={onClick} className={`${className} bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow grow-on-hover`}>
            <div className="flex items-center justify-between">
                <div className="mr-2">{title}</div>
                <div><CheckIcon className="w-5 h-5" /></div>
            </div>
        </button>
    );
}