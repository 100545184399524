import { axiosInstance } from './AxiosConfig';

export const registerUserActivity = (functionId, comment) => {
    axiosInstance.post('/shop/stats', {
        function_id: functionId,
        function_text: comment
    })
        .then(response => {
        })
        .catch(error => {
            console.error('Error registering activity:', error);
        });
};

