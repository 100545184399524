import {HeartIcon as OutlineHeartIcon} from "@heroicons/react/24/outline";
import {HeartIcon as SolidHeartIcon, ArrowDownIcon, StarIcon} from "@heroicons/react/24/solid";
import {FixedPricePill} from "./FixedPricePill";
import {axiosInstance} from "../../Api/AxiosConfig";
import React, {useState} from "react";
import {formatNumberWithSeparator, isExpired} from "../../Hooks/UseGenericFunctions";
import {AddToBasketButton} from "../../Components/ShoppingBasket/AddToBasketButton";
import {DivCell} from "./DivCell";
import {PurchaseTypePill} from "../../Components/Information/PurchaseTypePill";
import {OfferInput} from "./OfferInput";
import {VATIcon} from "../../Components/Cars/VATIcon";
import {CarzelleTooltip} from "../../Components/System/Messages/CarzelleTooltip";
import {PartnerListingCountDown} from "./PartnerListingCountDown";

export const PresalesListTableRow = ({car, activeCarId, fnUpdateCarList, fnSetActiveCar}) => {
    function formatNumber(number = 'en-US') {
        return new Intl.NumberFormat('da-DK').format(Math.floor(number));
    }

    const addLike = (hash) => {
        axiosInstance
            .post("shop/like", {hash: hash})

            .then(() => {
                fnUpdateCarList();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const removeLike = (hash) => {
        axiosInstance
            .delete("shop/like", {data: {hash: hash}})

            .then(() => {
                fnUpdateCarList();
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });
    }

    const handleRowClick = () => {
        fnSetActiveCar(car);
    }

    return (
        <div key={car.hash} onClick={handleRowClick} className={`flex items-center border-l-[5px] ${car.in_basket !== null ? 'border-purchase-color bg-gray-100 ' : activeCarId === car.hash ? 'border-l-gray-200 bg-gray-200' : 'border-l-white bg-white'} px-2 h-[41px] text-[12px] text-gray-500 cursor-pointer hover:bg-gray-100 hover:border-l-gray-100 hover:shadow transition duration-150 ease-in-out border-gray-100`}>
            <DivCell className="w-[45px]">
                {car.like &&
                    <div className="flex items-center justify-center" onClick={() => removeLike(car.hash)}><SolidHeartIcon className="w-4 h-4 text-gray-400"/></div>
                }
                {!car.like &&
                    <div className="flex items-center justify-center" onClick={() => addLike(car.hash)}><OutlineHeartIcon className="w-4 h-4 text-primary-color"/></div>
                }
            </DivCell>
            <DivCell className="w-[50px]">
                <div className="h-full w-[36px] relative">
                    <img id={"car_main_image_" + car.hash} className=' h-full my-1 object-scale-down rounded-full' src={car.list_image} alt={'image for ' + car.designation}/>
                    <div className={(car.new_on_list === 'true' ? '' : 'hidden') + ' absolute left-[-10px] top-[-0px]'}>
                        <CarzelleTooltip message="This car is new on the list">
                            <div className="rounded-full p-1 bg-green-600 text-white"><StarIcon className="w-3 h-3"/></div>
                        </CarzelleTooltip>
                    </div>
                </div>
            </DivCell>
            <DivCell>
                <div className="flex items-center">
                    #{car.car_id}
                </div>
            </DivCell>
            <DivCell className="w-[120px] xl:w-[250px] relative text-nowrap overflow-hidden text-ellipsis pt-[2px] h-[22px]">
                <div className="mr-2">{car.designation}</div>
                {car.expires_at_rest !== "" &&
                    <div className="absolute right-0 top-[2px]">
                        <PartnerListingCountDown seconds={car.expires_at_rest}/>
                    </div>
                }
            </DivCell>
            <DivCell className="w-[130px] hidden xl:block">
                <PurchaseTypePill car={car}/>
            </DivCell>
            <DivCell className="w-[100px]">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-right pr-4">
                    {car.reg_date_show}
                </span>
            </DivCell>
            <DivCell className="w-[60px]">
                <span className=" overflow-hidden overflow-ellipsis whitespace-nowrap text-right pr-4">
                    {formatNumber(car.km)}
                </span>
            </DivCell>
            <DivCell className="w-[80px] hidden xl:block">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap pr-4 flex items-center justify-end">
                    <div className={`${Number(car.damage_amount) < 0 ? 'hidden' : ''}`}>{formatNumberWithSeparator(Number(car.damage_amount), '.')}€</div>
                </span>
            </DivCell>
            <DivCell className="p-0 w-[160px]">
                <span className="overflow-hidden overflow-ellipsis whitespace-nowrap p-0">
                    <div className="flex items-center relative">
                        <div className={(car.price_info !== '' && !car.allow_offers ? 'absolute left-0' : 'hidden')}>
                            <CarzelleTooltip message={car.price_info}>
                                <div className="rounded-full p-1 bg-green-600 text-white"><ArrowDownIcon className="w-3 h-3"/></div>
                            </CarzelleTooltip>
                        </div>
                        <div className="min-w-[100px] text-right">{formatNumber(car.price)}&nbsp;{car.currency}</div>
                        <div className='flex items-center ml-2'><VATIcon vatStatusId={car.vat_status_id}/></div>
                    </div>
                </span>
            </DivCell>
            <DivCell className="w-[75px] flex justify-start">
                <div className={`overflow-hidden overflow-ellipsis whitespace-nowrap ${isExpired(car.expires_at_rest) ? 'hidden' : ''} `}>
                    <AddToBasketButton hash={car.hash} fnUpdate={fnUpdateCarList} text={car.buy_status !== 'listing' ? "Buy now" : null} bgColor={`${car.allow_offers || car.buy_status !== 'listing' ? 'bg-purchase-color' : 'bg-presales'} `} carInBasket={car.in_basket} tooltip="You can add this car to your list for consideration, including it to those you want us to acquire for you."/>
                </div>
            </DivCell>
            <DivCell className="w-[120]">
                <div className={`${!isExpired(car.expires_at_rest) ? '' : 'hidden'}`}>
                    <OfferInput car={car} fnUpdate={fnUpdateCarList}/>
                </div>
            </DivCell>
            <DivCell className="w-[55px] text-center hidden 2xl:block">
                {car.horsepower}
            </DivCell>
            <DivCell className="w-[160px] hidden 2xl:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.color}</div>
                </span>
            </DivCell>
            <DivCell className="w-[160px] hidden 2xl:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.fuel_name}</div>
                </span>
            </DivCell>
            <DivCell className="w-[160px] hidden 6xl:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.gear_name}</div>
                </span>
            </DivCell>
            <DivCell className="w-[160px] hidden 6xl:block">
                <span className="block overflow-hidden overflow-ellipsis whitespace-nowrap">
                    <div>{car.body_name}</div>
                </span>
            </DivCell>
        </div>
    )
}