import {formatNumberWithSeparator} from "../../Hooks/UseGenericFunctions";
import {DocumentIcon} from "@heroicons/react/24/outline";
import React from "react";

export const SuggestedPriceCalculation = ({car, data}) => {
    return (
        <>
            <div className="mt-6 font-bold">Suggested Price Calculation</div>
            <div className="flex mt-4">
                <div className='max-w-[750px] whitespace-normal'>
                    <div className="flex items-center justify-between">
                        <div className='text-sm'>Price</div>
                        <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_price), '.')}&nbsp;{car.currency}</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className='text-sm'>Tax</div>
                        <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.dk_tax_amount), '.')}&nbsp;{car.currency}</div>
                    </div>
                    <div className="flex items-center justify-between border-b border-b-solid ">
                        <div className='text-sm'>VAT</div>
                        <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.vat_amount), '.')}&nbsp;{car.currency}</div>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <div className='text-sm'>DK Total Net Price</div>
                        <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.total_price), '.')}&nbsp;{car.currency}</div>
                    </div>
                    <div className="flex items-center justify-between">
                        <div className='text-sm'>DK Suggested Retail Price</div>
                        <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.potential_tender_price), '.')}&nbsp;{car.currency}</div>
                    </div>
                    <div className="flex items-center justify-between mt-2 border-b border-dashed border-t">
                        <div className='text-sm'>Potential Profit</div>
                        <div className='ml-2 text-base'>{formatNumberWithSeparator(parseInt(data.dkPrice.profit), '.')}&nbsp;{car.currency}</div>
                    </div>
                    {data.dkPrice.tax_document &&
                        <div className="mt-4">
                            <div className='text-sm flex items-center'><DocumentIcon className="w-4 mr-2"/><a href={data.dkPrice.tax_document} className="text-sm underline" target="_new">Tax calculation documentation</a></div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}